import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap-daterangepicker/daterangepicker.css';
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./languages/i18n";
import "./assets/css/style.scss";
import { BrowserRouter } from 'react-router-dom';

import { createRoot } from "react-dom/client";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register({
  onUpdate: (sw) => {
    if (sw.waiting) {
      sw.waiting.postMessage({ type: "SKIP_WAITING" });

      window.location.reload();
      console.log("Update happened.");
    }
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
