import React from "react";
import { useTranslation } from "react-i18next";

function AddToHomeScreen() {
  const [deferredPrompt, setDeferredPrompt] = React.useState<any | null>(null);
  const [show, setShow] = React.useState<boolean>(false);
  const { t } = useTranslation();

  React.useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      setDeferredPrompt(e);

      setShow(true);
    });
  }, []);

  // bind to this
  const handleAddClick = () => {
    if (deferredPrompt) {
      setShow(false);
      // Show the prompt
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt");
        } else {
          console.log("User dismissed the A2HS prompt");
        }
        setDeferredPrompt(null);
      });
    } else {
      console.log("Invalid prompt object");
    }
  };

  if (!show) return null;

  return (
    <div className="background-add-icon-to-screen">
      <div className="content">
        <div className="title-add-icon-home-screen">
          Add app to the home screen
        </div>

        <div className="action">
          <button onClick={handleAddClick}>{t("Add")}</button>
          <button
            className="btn-cancel"
            onClick={() => {
              setShow(false);
            }}
          >
            {t("Cancel")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddToHomeScreen;
