import { Button, DatePicker, Divider, Form, Input, InputNumber, Modal, Table, Tooltip } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation, WithTranslation } from 'react-i18next';
import { IMyPricingItem, IMyPricingUpdateReq } from '../../models/managePricings.models';
import { getMyPricings, updateMyPricings } from '../../services/managePricings.service';
import formatPrice from '../../utils/format-price';
import { ReactComponent as SearchIcon } from "../../assets/icons/Search.svg";
import { ReactComponent as VectorCircle } from "../../assets/icons/Vector_Circle.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/Calendar.svg";
import { ReactComponent as CheckedIcon } from "../../assets/icons/Checked.svg";
import { ReactComponent as WarningIcon } from "../../assets/icons/Warn.svg";
import 'antd/dist/antd.css';

interface IPricingProps extends WithTranslation {
}

export default function ManagePrices(props: IPricingProps) {
  const [myPricings, setMyPricings] = useState<IMyPricingItem[]>([]);
  const [leftedInteractionTable, setLeftedInteractionTable] = useState<IMyPricingItem[]>([]);
  const [interactionPricings, setInteractionPricings] = useState<IMyPricingItem[]>([]);
  const [myPricingsUpdateReq, setMyPricingsUpdateReq] = useState<IMyPricingUpdateReq[]>([]);
  const [allchangeSaved, setAllChangeSaved] = useState<boolean>(false);
  const [errorFilterSelected, setErrorFilterSelected] = useState<boolean>(false);
  const [onForcusCanvas, setOnFocusCanvas] = useState<string>('');
  const [onFocusField, setOnFocusField] = useState<string>('');
  const [openDiscountModal, setOpenDiscountModal] = useState<boolean>(false);
  const [discountSelected, setDiscountSelected] = useState<number>(0);
  const [expirySelected, setExpirySelected] = useState<Date>();
  const [startDateSelected, setStartDateSelected] = useState<Date>();
  const [selectedCanvasForOverallDiscount, setSelectedCanvasForOverallDiscount] = useState<string[]>([]);
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [discountPending, setDiscountPending] = useState<boolean>(false);
  const [discountUpdated, setDiscountUpdated] = useState<boolean>(false);
  const { t } = useTranslation();
  const [modalData, setModalData] = useState<IMyPricingItem[]>([]);

  useEffect(() => {
    getData();
  }, [])

  const getData = async () => {
    const data = await getMyPricings();

    //format price
    setMyPricings([...data.data.map((p: IMyPricingItem) => ({ ...p }))]);
    setInteractionPricings([...data.data.map((p: IMyPricingItem) => ({ ...p }))]);
    setLeftedInteractionTable([...data.data.map((p: IMyPricingItem) => ({ ...p }))]);
    setModalData([...data.data.map((p: IMyPricingItem) => ({ ...p }))]);

    setDiscountSelected(0);
    setExpirySelected(undefined);
    setStartDateSelected(undefined);
  }

  const isInvalidRecord = (record: IMyPricingItem | undefined | IMyPricingUpdateReq) => {
    if (!record) return true;
    if (record.wholesaleDiscount > 0 && (!record.wholesaleDiscountStartDate || !record.wholesaleDiscountExpiry)) return true;
    if (record.wholesaleDiscountStartDate && record.wholesaleDiscountExpiry && (moment(record.wholesaleDiscountStartDate) > moment(record.wholesaleDiscountExpiry))) return true;

    return false;
  }

  const updateItem = (item: IMyPricingItem, field: string, pricingValue?: number, dateValue?: Date) => {
    clearSavedChangedAlert();
    clearPendingState();

    if (!item) return;

    if (field === 'retailPrice' || field === 'retailPriceAggressive' || field === 'retailPricePremium' ||
      field === 'tier1Price' || field === 'tier2Price' || field === 'tier3Price' ||
      field === 'tier4Price' || field === 'tier5Price' || field === 'tier6Price' || field === 'wholesaleDiscount') {
      if (pricingValue === undefined || pricingValue === null) return;
      item[field] = Number((Math.round(pricingValue * 100) / 100).toFixed(2));
    } else if (field === 'wholesaleDiscountStartDate' || field === 'wholesaleDiscountExpiry') {
      item[field] = dateValue;
    }

    //merge new data changed with lefted data

    setInteractionPricings([...interactionPricings]);
    setLeftedInteractionTable([...leftedInteractionTable.map((i) => {
      const existing = interactionPricings.find(it => it.canvasNumber === i.canvasNumber);
      if (existing) {
        return { ...existing };
      }
      return { ...i };
    })]);

    let myPricingUpdateItemIndex = myPricingsUpdateReq.findIndex(i => i.canvasNumber === item.canvasNumber);
    if (myPricingUpdateItemIndex >= 0) {
      myPricingsUpdateReq[myPricingUpdateItemIndex] = { ...myPricingsUpdateReq[myPricingUpdateItemIndex], ...item };
      setMyPricingsUpdateReq([...myPricingsUpdateReq]);
    } else {
      setMyPricingsUpdateReq([...myPricingsUpdateReq, { ...item }]);
    }
  }

  const handleUpdateMyPricings = async () => {
    if (!isValidToUpdate()) return;

    await updateMyPricings(myPricingsUpdateReq);
    getData();
    clearReqModal();

    let discountChangeSaved = false;

    if (discountPending) discountChangeSaved = true;

    clearReqModal();

    if (discountChangeSaved) setDiscountUpdated(true);
    else setAllChangeSaved(true);
  }

  const clearReqModal = () => {
    setMyPricingsUpdateReq([]);
    setAllChangeSaved(false);
    setErrorFilterSelected(false);
    setDiscountPending(false);
    setDiscountUpdated(false);
    setCheckedAll(false);
  }

  const clearPendingState = () => {
    setAllChangeSaved(false);
    setErrorFilterSelected(false);
    setDiscountPending(false);
    setDiscountUpdated(false);
    setCheckedAll(false);
  }

  const getExistOriginalItem = (canvasNumber: string | undefined) => {
    return myPricings.find(p => p.canvasNumber === canvasNumber);
  }

  const searchCanvas = (searchText: string, findErrorRecords: boolean = false) => {
    let searchedData = [];

    if (findErrorRecords) {
      searchedData = [...leftedInteractionTable]
        .filter(p => p && isInvalidRecord(p))
        .map((item: any) => ({ ...item }));
    } else {
      searchedData = [...leftedInteractionTable]
        .filter(p => p.canvasNumber.toLowerCase().includes(searchText.toLowerCase())
          || (p.canvasName && p.canvasName.toLowerCase().includes(searchText.toLowerCase())))
        .map((item: any) => ({ ...item }));
    }

    setInteractionPricings(searchedData);
  }

  const searchModalCanvas = (searchText: string) => {
    let searchedData = [];

    searchedData = [...myPricings]
      .filter(p => p.canvasNumber.toLowerCase().includes(searchText.toLowerCase())
        || (p.canvasName && p.canvasName.toLowerCase().includes(searchText.toLowerCase())))
      .map((item: any) => ({ ...item }));

    setModalData(searchedData);
  }

  const isValidToUpdate = (): boolean => {
    let isInvalid = false;
    myPricingsUpdateReq.forEach((reqItem) => {
      if (isInvalidRecord(reqItem)) {
        isInvalid = true;
        return;
      }
      ;
    });

    return !isInvalid;
  }

  const clearSavedChangedAlert = () => {
    setAllChangeSaved(false);
  }

  const disabledDate = (current: any) => {
    // Can not select days before today and today
    return current && current < moment().startOf('day');
  }

  const setDiscount = () => {
    if (discountSelected < 0 || discountSelected > 100 || !expirySelected || !startDateSelected) return;

    let interactionData: IMyPricingItem[] = [];
    let dataChangedUpdate: IMyPricingUpdateReq[] = [];

    [...myPricings].forEach(item => {
      let isItemSetDiscount = checkedAll || selectedCanvasForOverallDiscount.includes(item.canvasNumber);
      if (isItemSetDiscount) {
        let newItem = {
          ...item,
          wholesaleDiscount: discountSelected,
          wholesaleDiscountExpiry: expirySelected,
          wholesaleDiscountStartDate: startDateSelected
        };
        interactionData.push(newItem);
        dataChangedUpdate.push({
          ...item,
          wholesaleDiscount: discountSelected,
          wholesaleDiscountExpiry: expirySelected,
          wholesaleDiscountStartDate: startDateSelected
        } as IMyPricingUpdateReq);
      } else interactionData.push({ ...item });
    });

    setInteractionPricings(interactionData);
    setMyPricingsUpdateReq([...dataChangedUpdate]);
    setLeftedInteractionTable(interactionData);

    clearPendingState();
    setOpenDiscountModal(false);
    setDiscountPending(true);
  }

  const clearAllBasecostDiscount = () => {
    let interactionData: IMyPricingItem[] = [];
    let dataChangedUpdate: IMyPricingUpdateReq[] = [...myPricingsUpdateReq];

    [...myPricings].forEach(item => {
      if (item.wholesaleDiscount > 0 || item.wholesaleDiscountExpiry !== undefined || item.wholesaleDiscountStartDate !== undefined) {
        const existedItem = dataChangedUpdate.find((i) => item.canvasNumber === i.canvasNumber);
        if (existedItem) {
          existedItem.wholesaleDiscount = 0;
          existedItem.wholesaleDiscountExpiry = undefined;
          existedItem.wholesaleDiscountStartDate = undefined;
        } else {
          dataChangedUpdate.push({
            ...item,
            wholesaleDiscount: 0,
            wholesaleDiscountExpiry: undefined,
            wholesaleDiscountStartDate: undefined
          } as IMyPricingUpdateReq);
        }
      }

      item.wholesaleDiscount = 0;
      item.wholesaleDiscountExpiry = undefined;
      item.wholesaleDiscountStartDate = undefined;
      interactionData.push({ ...item });
    })

    setMyPricingsUpdateReq([...dataChangedUpdate]);
    setInteractionPricings(interactionData);
  }

  const config = {
    rules: [{ type: 'object' as const, required: true, message: 'This field is required!' }],
  };

  const columns = [
    {
      title: <>{t("managePrices.Shape")}</>,
      dataIndex: 'canvasNumber',
      key: 'canvasNumber',
      width: '10%',
      render: (canvas: any, record: any) => (<div className='canvas-title'>
        {record.canvasImage ? <img src={record.canvasImage}></img> : <div className='no-image'></div>}

        <div className={'title-section'}>
          <div className='title'>{record.canvasName}</div>
          <div className={'canvas-number ' + (record.canvasName ? '' : 'pdt-10')}>{record.canvasNumber}</div>
        </div>

      </div>),
      fixed: true,
      sorter: true
    },
    {
      title: <>{t("managePrices.RetailPriceAggressive")}</>,
      dataIndex: 'price',
      key: 'price',
      width: '10%',
      render: (text: any, record: any) => (<div className='price-column'>
        {onFocusField === 'retailPriceAggressive' && onForcusCanvas === record.canvasNumber ? (<>
          <Input type={'number'} id={`price-aggressive-${record.canvasNumber}`}
                 value={record.retailPriceAggressive} onChange={(value) => {
            updateItem(record, 'retailPriceAggressive', value.target.value as unknown as number);
          }}
                 onBlur={(e) => {
                   setOnFocusCanvas('');
                   setOnFocusField('');
                 }} autoFocus={true}/>
        </>) : (<>
          <div className='currency-input display-price' onClick={() => {
            setOnFocusCanvas(record.canvasNumber);
            setOnFocusField('retailPriceAggressive')
          }}>
            ${formatPrice(record.retailPriceAggressive)}
            {(record.retailPriceAggressive !== getExistOriginalItem(record.canvasNumber)?.retailPriceAggressive) &&
                <VectorCircle/>}
          </div>
        </>)}
      </div>),
      sorter: true
    },
    {
      title: <>{t("managePrices.RetailPrice")}</>,
      dataIndex: 'price',
      key: 'price',
      width: '10%',
      render: (text: any, record: any) => (<div className='price-column'>
        {onFocusField === 'retailPrice' && onForcusCanvas === record.canvasNumber ? (<>
          <Input type={'number'} id={`price-${record.canvasNumber}`}
                 value={record.retailPrice} onChange={(value) => {
            updateItem(record, 'retailPrice', value.target.value as unknown as number);
          }}
                 onBlur={(e) => {
                   setOnFocusCanvas('');
                   setOnFocusField('');
                 }} autoFocus={true}/>
        </>) : (<>
          <div className='currency-input display-price' onClick={() => {
            setOnFocusCanvas(record.canvasNumber);
            setOnFocusField('retailPrice')
          }}>
            ${formatPrice(record.retailPrice)}
            {(record.retailPrice !== getExistOriginalItem(record.canvasNumber)?.retailPrice) && <VectorCircle/>}
          </div>
        </>)}
      </div>),
      sorter: true
    },
    {
      title: <>{t("managePrices.RetailPricePremium")}</>,
      dataIndex: 'price',
      key: 'price',
      width: '10%',
      render: (text: any, record: any) => (<div className='price-column'>
        {onFocusField === 'retailPricePremium' && onForcusCanvas === record.canvasNumber ? (<>
          <Input type={'number'} id={`price-premium-${record.canvasNumber}`}
                 value={record.retailPricePremium} onChange={(value) => {
            updateItem(record, 'retailPricePremium', value.target.value as unknown as number);
          }}
                 onBlur={(e) => {
                   setOnFocusCanvas('');
                   setOnFocusField('');
                 }} autoFocus={true}/>
        </>) : (<>
          <div className='currency-input display-price' onClick={() => {
            setOnFocusCanvas(record.canvasNumber);
            setOnFocusField('retailPricePremium')
          }}>
            ${formatPrice(record.retailPricePremium)}
            {(record.retailPricePremium !== getExistOriginalItem(record.canvasNumber)?.retailPricePremium) &&
                <VectorCircle/>}
          </div>
        </>)}
      </div>),
      sorter: true
    },
    {
      title: <>{t("managePrices.tier1Price")}</>,
      dataIndex: 'tier1Price',
      key: 'tier1Price',
      width: '10%',
      render: (text: any, record: any) => (<div className='price-column'>
        {onFocusField === 'tier1Price' && onForcusCanvas === record.canvasNumber ? (<>
          <Input type={'number'} id={`price-${record.canvasNumber}`}
                 value={record.tier1Price} onChange={(value) => {
            updateItem(record, 'tier1Price', value.target.value as unknown as number);
          }}
                 onBlur={(e) => {
                   setOnFocusCanvas('');
                   setOnFocusField('');
                 }} autoFocus={true}/>
        </>) : (<>
          <div className='currency-input display-price' onClick={() => {
            setOnFocusCanvas(record.canvasNumber);
            setOnFocusField('tier1Price')
          }}>
            ${formatPrice(record.tier1Price)}
            {(record.tier1Price !== getExistOriginalItem(record.canvasNumber)?.tier1Price) && <VectorCircle/>}
          </div>
        </>)}
      </div>),
      sorter: true
    },
    {
      title: <>{t("managePrices.tier2Price")}</>,
      dataIndex: 'tier2Price',
      key: 'tier2Price',
      width: '10%',
      render: (text: any, record: any) => (<div className='price-column'>
        {onFocusField === 'tier2Price' && onForcusCanvas === record.canvasNumber ? (<>
          <Input type={'number'} id={`price-${record.canvasNumber}`}
                 value={record.tier2Price} onChange={(value) => {
            updateItem(record, 'tier2Price', value.target.value as unknown as number);
          }}
                 onBlur={(e) => {
                   setOnFocusCanvas('');
                   setOnFocusField('');
                 }} autoFocus={true}/>
        </>) : (<>
          <div className='currency-input display-price' onClick={() => {
            setOnFocusCanvas(record.canvasNumber);
            setOnFocusField('tier2Price')
          }}>
            ${formatPrice(record.tier2Price)}
            {(record.tier2Price !== getExistOriginalItem(record.canvasNumber)?.tier2Price) && <VectorCircle/>}
          </div>
        </>)}
      </div>),
      sorter: true
    },
    {
      title: <>{t("managePrices.tier3Price")}</>,
      dataIndex: 'tier3Price',
      key: 'tier3Price',
      width: '10%',
      render: (text: any, record: any) => (<div className='price-column'>
        {onFocusField === 'tier3Price' && onForcusCanvas === record.canvasNumber ? (<>
          <Input type={'number'} id={`price-${record.canvasNumber}`}
                 value={record.tier3Price} onChange={(value) => {
            updateItem(record, 'tier3Price', value.target.value as unknown as number);
          }}
                 onBlur={(e) => {
                   setOnFocusCanvas('');
                   setOnFocusField('');
                 }} autoFocus={true}/>
        </>) : (<>
          <div className='currency-input display-price' onClick={() => {
            setOnFocusCanvas(record.canvasNumber);
            setOnFocusField('tier3Price')
          }}>
            ${formatPrice(record.tier3Price)}
            {(record.tier3Price !== getExistOriginalItem(record.canvasNumber)?.tier3Price) && <VectorCircle/>}
          </div>
        </>)}
      </div>),
      sorter: true
    },
    {
      title: <>{t("managePrices.tier4Price")}</>,
      dataIndex: 'tier4Price',
      key: 'tier4Price',
      width: '10%',
      render: (text: any, record: any) => (<div className='price-column'>
        {onFocusField === 'tier4Price' && onForcusCanvas === record.canvasNumber ? (<>
          <Input type={'number'} id={`price-${record.canvasNumber}`}
                 value={record.tier4Price} onChange={(value) => {
            updateItem(record, 'tier4Price', value.target.value as unknown as number);
          }}
                 onBlur={(e) => {
                   setOnFocusCanvas('');
                   setOnFocusField('');
                 }} autoFocus={true}/>
        </>) : (<>
          <div className='currency-input display-price' onClick={() => {
            setOnFocusCanvas(record.canvasNumber);
            setOnFocusField('tier4Price')
          }}>
            ${formatPrice(record.tier4Price)}
            {(record.tier4Price !== getExistOriginalItem(record.canvasNumber)?.tier4Price) && <VectorCircle/>}
          </div>
        </>)}
      </div>),
      sorter: true
    },
    {
      title: <>{t("managePrices.tier5Price")}</>,
      dataIndex: 'tier5Price',
      key: 'tier5Price',
      width: '10%',
      render: (text: any, record: any) => (<div className='price-column'>
        {onFocusField === 'tier5Price' && onForcusCanvas === record.canvasNumber ? (<>
          <Input type={'number'} id={`price-${record.canvasNumber}`}
                 value={record.tier5Price} onChange={(value) => {
            updateItem(record, 'tier5Price', value.target.value as unknown as number);
          }}
                 onBlur={(e) => {
                   setOnFocusCanvas('');
                   setOnFocusField('');
                 }} autoFocus={true}/>
        </>) : (<>
          <div className='currency-input display-price' onClick={() => {
            setOnFocusCanvas(record.canvasNumber);
            setOnFocusField('tier5Price')
          }}>
            ${formatPrice(record.tier5Price)}
            {(record.tier5Price !== getExistOriginalItem(record.canvasNumber)?.tier5Price) && <VectorCircle/>}
          </div>
        </>)}
      </div>),
      sorter: true
    },
    {
      title: <>{t("managePrices.tier6Price")}</>,
      dataIndex: 'tier6Price',
      key: 'tier6Price',
      width: '10%',
      render: (text: any, record: any) => (<div className='price-column'>
        {onFocusField === 'tier6Price' && onForcusCanvas === record.canvasNumber ? (<>
          <Input type={'number'} id={`price-${record.canvasNumber}`}
                 value={record.tier6Price} onChange={(value) => {
            updateItem(record, 'tier6Price', value.target.value as unknown as number);
          }}
                 onBlur={(e) => {
                   setOnFocusCanvas('');
                   setOnFocusField('');
                 }} autoFocus={true}/>
        </>) : (<>
          <div className='currency-input display-price' onClick={() => {
            setOnFocusCanvas(record.canvasNumber);
            setOnFocusField('tier6Price')
          }}>
            ${formatPrice(record.tier6Price)}
            {(record.tier6Price !== getExistOriginalItem(record.canvasNumber)?.tier6Price) && <VectorCircle/>}
          </div>
        </>)}
      </div>),
      sorter: true
    },
    {
      title: <>{t("managePrices.wholesaleDiscount")}</>,
      dataIndex: 'wholesaleDiscount',
      key: 'wholesaleDiscount',
      width: '10%',
      render: (text: any, record: any) => (<div className='price-column'>
        {onFocusField === 'wholesaleDiscount' && onForcusCanvas === record.canvasNumber ? (<>
          <Input type={'number'} id={`price-${record.canvasNumber}`}
                 value={record.wholesaleDiscount} onChange={(value) => {
            updateItem(record, 'wholesaleDiscount', value.target.value as unknown as number);
          }}
                 onBlur={(e) => {
                   setOnFocusCanvas('');
                   setOnFocusField('');
                 }} autoFocus={true} min={0} max={100}/>
        </>) : (<>
          <div className='currency-input display-price' onClick={() => {
            setOnFocusCanvas(record.canvasNumber);
            setOnFocusField('wholesaleDiscount')
          }}>
            {`${record.wholesaleDiscount}%`}
            {(record.wholesaleDiscount !== getExistOriginalItem(record.canvasNumber)?.wholesaleDiscount) &&
                <VectorCircle/>}
          </div>
        </>)}
        {/* {(record.wholesaleDiscount > 0 && (!record.wholesaleDiscountStartDate || !record.wholesaleDiscountExpiry)) && (<span className='error error-message'><WarningIcon/>Lesser than Base Cost</span>)} */}
      </div>),
      sorter: true
    },
    {
      title: <>{t("managePrices.wholesaleDiscountStartDate")}</>,
      dataIndex: 'wholesaleDiscountStartDate',
      key: 'wholesaleDiscountStartDate',
      width: '20%',
      render: (text: any, record: IMyPricingItem) => (<div className={'calendar-column' +
        (record.wholesaleDiscount > 0 && !record.wholesaleDiscountStartDate ? ' error' : '')}>
        <div>
          <div className='calendar'>
            <CalendarIcon></CalendarIcon>
            <DatePicker
              className='discount-expiry-picker'
              value={record.wholesaleDiscountStartDate ? moment(record.wholesaleDiscountStartDate) : undefined}
              onChange={(value) => {
                updateItem(record, 'wholesaleDiscountStartDate', undefined, value ? value.toDate() : undefined);
              }}
              format={'MMM DD,yyyy'}
              disabledDate={disabledDate}
            />
          </div>
        </div>
        {(record.wholesaleDiscount > 0 && !record.wholesaleDiscountStartDate) && (
          <span className='error error-message'><WarningIcon/>This field is required</span>)}
      </div>),
      sorter: true
    },
    {
      title: <>{t("managePrices.wholesaleDiscountExpiry")}</>,
      dataIndex: 'wholesaleDiscountExpiry',
      key: 'wholesaleDiscountExpiry',
      width: '20%',
      render: (text: any, record: any) => (<div className={'calendar-column' +
        ((record.wholesaleDiscount > 0 && !record.wholesaleDiscountExpiry)
        || (record.wholesaleDiscountStartDate && record.wholesaleDiscountExpiry && moment(record.wholesaleDiscountStartDate) > moment(record.wholesaleDiscountExpiry)) ? ' error' : '')}>
        <div>
          <div className='calendar'>
            <CalendarIcon></CalendarIcon>
            <DatePicker
              className='discount-expiry-picker'
              value={record.wholesaleDiscountExpiry ? moment(record.wholesaleDiscountExpiry) : undefined}
              onChange={(value) => {
                updateItem(record, 'wholesaleDiscountExpiry', undefined, value ? value.toDate() : undefined);
              }}
              format={'MMM DD,yyyy'}
              disabledDate={(current) => {
                return current && current < moment(record.wholesaleDiscountStartDate).startOf('day')
              }}
            />
          </div>
        </div>
        {(record.wholesaleDiscountStartDate && record.wholesaleDiscountExpiry && moment(record.wholesaleDiscountStartDate) > moment(record.wholesaleDiscountExpiry)) && (
          <span className='error error-message'><WarningIcon/>Expiry must be greater than start date</span>)}
        {(record.wholesaleDiscount > 0 && !record.wholesaleDiscountExpiry) && (
          <span className='error error-message'><WarningIcon/>This field is required</span>)}
      </div>),
      sorter: true
    },
  ];

  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    const sortDirection = sorter.order === 'ascend' ? 1 : -1;
    let sortedData = [];

    const numberTypeColumns = ['price', 'tier1Price', 'tier2Price', 'tier3Price', 'tier4Price', 'tier5Price',
      'tier6Price', 'wholesaleDiscount'];
    const textTypeColumns = ['canvasNumber'];
    const dateTypeColumns = ['wholesaleDiscountStartDate', 'wholesaleDiscountExpiry']

    if (sorter && sorter.columnKey) {
      if (textTypeColumns.find(x => x === sorter.columnKey)) {
        const sortField = (textTypeColumns.find(x => x === sorter.columnKey));
        sortedData = interactionPricings.sort((a: IMyPricingItem, b: IMyPricingItem) => {
          if (!a[sortField as keyof IMyPricingItem]) return -1 * sortDirection;
          if (!b[sortField as keyof IMyPricingItem]) return 1 * sortDirection;
          if ((a[sortField as keyof IMyPricingItem] as string).toUpperCase() > (b[sortField as keyof IMyPricingItem] as string).toUpperCase()) return 1 * sortDirection;
          else return -1 * sortDirection;
        });
      } else if (numberTypeColumns.find(x => x === sorter.columnKey)) {
        const sortField = (numberTypeColumns.find(x => x === sorter.columnKey));
        sortedData = interactionPricings.sort((a, b) => {
          return ((a[sortField as keyof IMyPricingItem] as unknown as number) > (b[sortField as keyof IMyPricingItem] as unknown as number) ? 1 : -1) * sortDirection;
        });
      } else if (dateTypeColumns.find(x => x === sorter.columnKey)) {
        const sortField = (dateTypeColumns.find(x => x === sorter.columnKey));
        sortedData = interactionPricings.sort((a, b) =>
          (new Date(a[sortField as keyof IMyPricingItem] as Date ? a[sortField as keyof IMyPricingItem] as Date : '1/1/1970').getTime() - new Date(b[sortField as keyof IMyPricingItem] as Date ? b[sortField as keyof IMyPricingItem] as Date : '1/1/1970').getTime()) * sortDirection)
      } else {
        sortedData = interactionPricings;
      }
    } else {
      sortedData = interactionPricings;
    }
    setInteractionPricings([...sortedData]);
  }

  const modalColumns = [
    {
      title: "Select",
      width: '10%',
      render: (index: any, record: any) => (<div className='select-column'>
        <input
          className="form-check-input cur"
          type="checkbox"
          checked={checkedAll || selectedCanvasForOverallDiscount.includes(record.canvasNumber)}
          id="checkAll"
          onChange={(e) => {
            if (selectedCanvasForOverallDiscount.includes(record.canvasNumber)) {
              setSelectedCanvasForOverallDiscount([...selectedCanvasForOverallDiscount.filter(x => x !== record.canvasNumber)])
            } else {
              setSelectedCanvasForOverallDiscount([...selectedCanvasForOverallDiscount, record.canvasNumber]);
            }
          }
          }
        />
      </div>)
    },
    {
      title: <>{t("managePrices.Shape")}</>,
      dataIndex: 'canvasNumber',
      key: 'canvas',
      width: '90%',
      render: (canvas: any, record: any) => (<div className='canvas-title'>
        {record.canvasImage ? <img src={record.canvasImage}></img> : <div className='no-image'></div>}

        <div className={'title-section'}>
          <div className='title'>{record.canvasName}</div>
          <div className={'canvas-number ' + (record.canvasName ? '' : 'pdt-10')}>{record.canvasNumber}</div>
        </div>

      </div>),
      fixed: true,
    }
  ]

  return (
    <div id='my-pricing'>
      <div className='my-pricing-header'>
        <h1>{t("managePrices.managePrices")}</h1>
      </div>

      <div className='my-pricing-body'>
        <div className='table-actions'>
          <div className='actions-left'>
            <div style={{ display: 'flex' }}>
              <Button
                className={'default-btn primary-btn margin-right-10 apply-btn ' + (myPricingsUpdateReq && myPricingsUpdateReq.length > 0 && isValidToUpdate() ? 'active' : (!myPricingsUpdateReq || myPricingsUpdateReq.length === 0) ? '' : 'disabled error')}
                onClick={handleUpdateMyPricings}
                disabled={!(myPricingsUpdateReq && myPricingsUpdateReq.length > 0 && isValidToUpdate())}>{t("managePrices.SaveChange")}</Button>
              <Button className={'default-btn margin-right-10'} onClick={() => {
                setOpenDiscountModal(true);
              }}>{t("managePrices.ApplyAnOverallDiscount")}</Button>
              <Button className={'default-btn margin-right-10'} onClick={() => {
                clearAllBasecostDiscount();
              }}>{t("managePrices.clearAllBasecostDiscount")}</Button>
            </div>
            <div className='info-section'>
              {myPricingsUpdateReq && myPricingsUpdateReq.length > 0 && isValidToUpdate() && !errorFilterSelected &&
                  <span className='pending-change'>Pending changes</span>}

              {!isValidToUpdate() && !errorFilterSelected &&
                  <a className='pending-change error underline' onClick={() => {
                    setErrorFilterSelected(true);
                    searchCanvas('', true);
                  }}>Show Pricing Errors</a>}
              {errorFilterSelected && <a className='pending-change	 underline' onClick={() => {
                setErrorFilterSelected(false);
                searchCanvas('', false);
              }}>Return to original list</a>}
              {allchangeSaved && <span className='successful-change'><CheckedIcon/>All changes has been saved!</span>}
              {discountUpdated && <span className='successful-change'><CheckedIcon/>Discount has been applied!</span>}
            </div>

          </div>
          <div className='actions-right'>

            <Input prefix={<SearchIcon/>} placeholder={'Search Canvas'} onChange={(e) => {
              searchCanvas(e.target.value);
            }}/>
          </div>
        </div>

        <div className='my-pricings-table'>
          <Table
            rowKey="canvasNumber"
            columns={columns}
            dataSource={interactionPricings}
            pagination={{ defaultPageSize: 10, showSizeChanger: true }}
            scroll={{ x: true }}
            onChange={onTableChange}
          />
        </div>
      </div>


      <Modal
        className='my-pricing-modal'
        visible={openDiscountModal}
        //onOk={() => {if (discountSelected > 0 && expirySelected) { setDiscount(); setOpenDiscountModal(false);}}}
        onCancel={() => {
          setOpenDiscountModal(false)
        }}
        title="Discount"
        //okText="Proceed"
        footer={[<>
          <Button className='cancel' onClick={() => {
            setOpenDiscountModal(false)
          }}>Cancel</Button>
          <Button className='submit' form="form-discount" key="submit" htmlType="submit">
            Proceed
          </Button>
        </>
        ]}
      >
        <p>Update Discount and Expiry for your price. This number is a percentage so 10 means 10% reduction on your
          prices.</p>
        <Form
          name="form-discount"
          onFinish={() => {
            setDiscount()
          }}
          initialValues={{ discount: 0, expiryDate: null, startDate: null }}
        >
          <div className='row ant-row'>
            <div className='discount-input-form-section ant-col ant-col-8'>
              <Form.Item
                name="discount"
                rules={[{ required: true, message: 'Please input your Discount!' },
                  { type: 'number', min: 0, max: 100, message: 'Discount must between 0 and 100' }]}
              >
                <InputNumber onChange={(value) => {
                  setDiscountSelected(value as unknown as number)
                }}/>
              </Form.Item>
            </div>

            <div className='discount-input-form-section ant-col ant-col-8'>
              <Form.Item
                name="startDate"
                {...config}
              >
                <DatePicker
                  name='expiryDate'
                  className='discount-expiry-picker'
                  onChange={(value) => {
                    setStartDateSelected(value ? value.toDate() : undefined);
                  }}
                  format={'MMM DD,yyyy'}
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </div>

            <div className='discount-input-form-section ant-col ant-col-8'>
              <Form.Item
                name="expiryDate"
                {...config}
              >
                <DatePicker
                  name='expiryDate'
                  className='discount-expiry-picker'
                  onChange={(value) => {
                    setExpirySelected(value ? value.toDate() : undefined);
                  }}
                  format={'MMM DD,yyyy'}
                  disabledDate={(current) => {
                    return current && current < moment(startDateSelected).startOf('day')
                  }}
                />
              </Form.Item>
            </div>

            <div className='ant-col ant-col-24'>
              <div className='table-actions'>
                <div className='actions-left'>
                  <input
                    className="form-check-input checked-all cur"
                    type="checkbox"
                    checked={checkedAll}
                    id="checkAll"
                    onChange={(e) =>
                      setCheckedAll((pre) => !pre)
                    }
                  /> Select all
                </div>

                <div className='actions-right'>
                  <Input prefix={<SearchIcon/>} placeholder={'Search Canvas'} onChange={(e) => {
                    searchModalCanvas(e.target.value);
                  }}/>
                </div>
              </div>

              <Table rowKey="canvasNumber"
                     columns={modalColumns}
                     dataSource={modalData}
                     pagination={{ defaultPageSize: 10, showSizeChanger: true }}></Table>
            </div>
          </div>
        </Form>
      </Modal>

    </div>
  )
}
