import axios, { AxiosInstance, AxiosResponse, ResponseType } from "axios";
import qs from "qs";
import { toggleLoading } from "../components/shared/LoadingIndicator";
import { API_BASE_URL } from "../constants/url-config";
import { authorizationHeader } from "./helper";
import { notification } from 'antd';

const axiosInstance = (
  handleErrorAutomatic: boolean,
  contentType: string = "application/json",
  responseType: ResponseType = "json",
  successMessage?: string
): AxiosInstance => {
  if (handleErrorAutomatic) {
    toggleLoading(true);
  }
  const instance = axios.create({
    baseURL: API_BASE_URL,
    headers: Object.assign(
      {
        "Content-Type": contentType,
      },
      authorizationHeader()
    ),
    responseType: responseType,
  });

  instance.interceptors.response.use(
    (response) => {
      if (handleErrorAutomatic) {
        toggleLoading(false);
      }
      return response;
    },
    (error) => {
      let errorMesasge = error.response?.data?.message || error.response?.data?.Message || error.message;
     openNotification('Request error', errorMesasge);
      if (handleErrorAutomatic) {
        toggleLoading(false);
      }

      return Promise.reject(error);
    }
  );
  return instance;
};

export const getAsync = (
  url: string,
  params?: { [key: string]: any },
  handleErrorAutomatic: boolean = true
): Promise<AxiosResponse> => {
  if (params?.hasOwnProperty("keyword")) {
    params.keyword = params.keyword.trim();
  }
  return axiosInstance(handleErrorAutomatic).get(url, {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  })
};

export const postAsync = (
  url: string,
  json: object,
  successMessage?: string,
  handleErrorAutomatic: boolean = true
): Promise<AxiosResponse> => {
  return axiosInstance(
    handleErrorAutomatic,
    undefined,
    undefined,
    successMessage
  ).post(url, json);
};

export const putAsync = (
  url: string,
  json?: object,
  successMessage?: string,
  handleErrorAutomatic: boolean = true
): Promise<AxiosResponse> => {
  return axiosInstance(
    handleErrorAutomatic,
    undefined,
    undefined,
    successMessage
  ).put(url, json);
};

export const deleteAsync = (
  url: string,
  successMessage?: string,
  handleErrorAutomatic: boolean = true
): Promise<AxiosResponse> => {
  return axiosInstance(
    handleErrorAutomatic,
    undefined,
    undefined,
    successMessage
  ).delete(url);
};

export const downloadAsync = (
  url: string,
  params?: object
): Promise<AxiosResponse> => {
  return axiosInstance(true, undefined, "blob").get(url, {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
};

export const postDownloadFileAsync = (
  url: string,
  params?: object
): Promise<AxiosResponse> => {
  return axiosInstance(true, undefined, "blob").post(url, params);
};

export const postWithFormFileAsync = (
  url: string,
  file: File,
  folder: string
) => {
  const formData = new FormData();
  formData.append(file.name, file);
  formData.append("folder", folder);

  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    headers: Object.assign(
      { "content-Type": "multipart/form-data" },
      authorizationHeader()
    ),
  });

  return instance.post(url, formData);
};

export const putWithFormFileAsync = (url: string, file: File) => {
  return axios.put(url, file, {
    headers: { "Content-Type": "application/octet-stream" },
  });
};

const openNotification = (notificationTitle: string, notificationDescription: string) => {
  notification.open({
    message: notificationTitle,
    description: notificationDescription,
    duration: 20
  });
};