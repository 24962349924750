import { AxiosResponse } from 'axios';
import {
  IMyPricingUpdateReq,
  IUpdateWholesaleLevelReq,
  IWholesaleLevelRes,
} from '../models/managePricings.models';
import { getAsync, postAsync, putAsync } from '../utils/http-client';

export const getMyPricings = (): Promise<AxiosResponse> => {
  return getAsync("/manage-wholesale/api/prices");
}

export const updateMyPricings = (req: IMyPricingUpdateReq[]): Promise<AxiosResponse> => {
  return putAsync("/manage-wholesale/api/prices", req);
}

export const updateWholesaleLevel = (params: IUpdateWholesaleLevelReq): Promise<AxiosResponse> => {
  const url = "manage-wholesale/api/levels/set-level";
  return putAsync(url, params);
};

export const getWholesaleLevel = (artistId: Number): Promise<AxiosResponse<IWholesaleLevelRes>> => {
  const url = `manage-wholesale/api/levels/artist/${artistId}`;
  return getAsync(url);
};
