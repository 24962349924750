import React, { Suspense, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import AddToHomeScreen from "./components/shared/install-app";
import { Loading } from "./components/shared/Loading";
import LoadingIndicator from "./components/shared/LoadingIndicator";
import { getToken } from "./helpers/storage";
import { Redirect, Switch, useLocation } from "react-router-dom";
import { Route } from "react-router";
import { ROUTE_PATHS } from "./constants/url-config";
import ManagePrices from './components/manage-prices/managePrices';
import Navbar from './components/navbar/navbar';
import 'antd/dist/antd.css';

const SignIn = React.lazy(() => import("./components/auth/SignIn"));
const PrintRequest = React.lazy(
  () => import("./components/print-request/PrintRequest")
);
const PrintRequestDetail = React.lazy(
  () => import("./components/print-request/detail/detail")
);
const ProductError = React.lazy(
  () => import("./components/error/product")
);
const OrderDetail = React.lazy(
  () => import("./components/order-detail/OrderDetail")
);
const TFTool = React.lazy(
  () => import("./components/transfer-file/TFTools")
);
const Customer = React.lazy(
  () => import("./components/customers/Customer")
);

function App() {
  const [hasToken, setHasToken] = useState<boolean>(false);
  let location = useLocation();

  useEffect(() => {
    const token = getToken();

    setHasToken(token ? true : false);
  }, [location]);

  const routes = [
    {
      href: ROUTE_PATHS.SignIn,
      exact: true,
      title: "Sign in",
      component: SignIn,
    },
    {
      href: ROUTE_PATHS.PRS,
      title: "Print request",
      component: PrintRequest,
      exact: true,
    },
    {
      href: ROUTE_PATHS.ManagePrices,
      title: "Manage Prices",
      component: ManagePrices,
      exact: true
    },
    {
      href: ROUTE_PATHS.TFTools,
      title: "TF Tools",
      component: TFTool,
      exact: true
    },
    {
      href: ROUTE_PATHS.PRSDetail,
      title: "Print request detail",
      component: PrintRequestDetail,
      exact: true,
    },
    {
      href: ROUTE_PATHS.ProductError,
      title: "Product Error",
      component: ProductError
    },
    {
      href: ROUTE_PATHS.OrderDetail,
      title: "Order Details",
      component: OrderDetail,
      exact: true,
    },
    {
      href: ROUTE_PATHS.ManageCustomers,
      title: "Manage Customers",
      component: Customer,
      exact: true,
    }
  ];

  return (
    <Suspense fallback={<Loading />}>
      <LoadingIndicator />

      <AddToHomeScreen />

      <div className="App">
        <header></header>
        <main className="container">
        {hasToken && <Navbar></Navbar>}         
            <Switch>
              {routes.map((route, index) => (
                <Route
                  key={index}
                  path={route.href}
                  exact={route.exact}
                  component={route.component}
                />
              ))}

              <Redirect
                from={!hasToken ? ROUTE_PATHS.PRS : ROUTE_PATHS.SignIn}
                to={!hasToken ? ROUTE_PATHS.PRS : ROUTE_PATHS.SignIn}
              />
            </Switch>
        </main>
      </div>
    </Suspense>
  );
}

export default withTranslation()(App);
