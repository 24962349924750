export enum StorageType {
  Token = "Token",
  User = "User",
  language = "language",
}

export enum OrderDirection {
  DESC = "DESC",
  ASC = "ASC",
}
