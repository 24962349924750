export const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT || "";

export enum ROUTE_PATHS {
  SignIn = "/",
  PRS = "/prs",
  PRSDetail = "/prs/:id",
  TFTools = "/tfs",
  ProductError = "/defect/:id",
  OrderDetail = "/:orderId",
  ManagePrices = "/manage-prices",
  ManageCustomers = "/customer/:customerId"
}
