import { IUser } from "../models/auth.models";
import { StorageType } from "../models/common.models";

export const setLanguage = (language: string): void => {
  localStorage.setItem(StorageType.language, language);
};
export const getLanguage = (): string => {
  const language = localStorage.getItem(StorageType.language);
  return language == null ? "en" : language;
};

export const setStorageToken = (token: string) => {
  localStorage.setItem(StorageType.Token, token);
};

export const getToken = () => {
  const token = localStorage.getItem(StorageType.Token);
  return token ? token : "";
};

export const setStorageUser = (user: IUser) => {
  localStorage.setItem(StorageType.User, JSON.stringify(user));
};

export const getStorageUser = (): IUser => {
  const user = localStorage.getItem(StorageType.User);
  return user ? JSON.parse(user) : {};
};

export const logOut = () => {
  localStorage.clear();
};
