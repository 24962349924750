const formatPrice = (price: number, currencyCode?: string): string =>  {
	if (price == undefined)
			price = 0;

	let priceFormat;
	if(currencyCode){
	priceFormat = new Intl.NumberFormat('en-US',{ style:'currency', currency: currencyCode, maximumFractionDigits:2, minimumFractionDigits:2}).format(price);
	let response;
	if(currencyCode==='CAD'){
			response= priceFormat.replace('CA$','');
	}
	else{
			response=priceFormat.replace('$','');
	}
	return  `${response} ${currencyCode}`;
	
	}
	priceFormat =new Intl.NumberFormat('en-US',{maximumFractionDigits:2,minimumFractionDigits:2}).format(price);
	return priceFormat;
}
export default formatPrice;
