import {  Link, NavLink, useHistory } from "react-router-dom";
import logo from "../../assets/images/logo_SM.png";
import { ROUTE_PATHS } from '../../constants/url-config';
import { logOut } from '../../helpers/storage';

const Navbar= () =>{
	const history = useHistory();
  return (
				<div className="topnav">
					<div className="ant-col ant-col-5 logo-header">
						<img className="logo" src={logo} />
					</div>
					<div className="ant-col ant-col-14">

						{/* Hide the navigation buttons for temporary */}

						{/* <NavLink
								to="/prs"
								activeClassName="active"
							>Print Requests</NavLink>
							<NavLink
								to="/manage-prices"
								activeClassName="active"
							>Manage Prices</NavLink> */}
					</div>
					<div className="ant-col ant-col-5">
						<button
							type="button"
							className="form-control default-btn btn-logout"
							onClick={() => {
								logOut();
								history.push(ROUTE_PATHS.SignIn);
							}}
						>
							Log out
						</button>
					</div>
						
				</div>
  );
}
export default Navbar;