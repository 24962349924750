import { AxiosResponse } from "axios";
import { getToken } from "../helpers/storage";

export const authorizationHeader = (): { [index: string]: string } => {
  const token = getToken();
  if (token) {
    return { Authorization: `Bearer ${token}` };
  }
  return {};
};

export const fileDownloader = (
  response: AxiosResponse<any>,
  fileName: string
) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};
